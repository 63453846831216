// // i18n
// import "./locales/i18n";

// // highlight
// import "./utils/highlight";

// // scroll bar
// // import "simplebar/src/simplebar.css";
// import "simplebar-react/dist/simplebar.min.css";

// // lightbox
// // import "react-image-lightbox/style.css";

// // map
// // import "mapbox-gl/dist/mapbox-gl.css";

// // editor
// import "react-quill/dist/quill.snow.css";

// // slick-carousel
// // import "slick-carousel/slick/slick.css";
// // import "slick-carousel/slick/slick-theme.css";

// // lazy image
// import "react-lazy-load-image-component/src/effects/blur.css";
// import "react-lazy-load-image-component/src/effects/opacity.css";
// import "react-lazy-load-image-component/src/effects/black-and-white.css";

// import ReactDOM from "react-dom/client";
// import { Provider as ReduxProvider } from "react-redux";
// import { PersistGate } from "redux-persist/lib/integration/react";
// // @mui
// // import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// // import { LocalizationProvider } from "@mui/x-date-pickers";
// // redux
// import { store, persistor } from "./redux/store";
// // contexts
// import { SettingsProvider } from "./contexts/SettingsContext";

// //
// import React from "react";
// import App from "./App";

// import reportWebVitals from "./reportWebVitals";
// import { AuthProvider } from "./contexts/JWTContext";
// import { HelmetProvider } from "react-helmet-async";
// import { BrowserRouter } from "react-router-dom";
// import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <AuthProvider>
//     <HelmetProvider>
//       <ReduxProvider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
//           <SettingsProvider>
//             <CollapseDrawerProvider>
//               <BrowserRouter>
//                 <App />
//               </BrowserRouter>
//             </CollapseDrawerProvider>
//           </SettingsProvider>
//           {/* </LocalizationProvider> */}
//         </PersistGate>
//       </ReduxProvider>
//     </HelmetProvider>
//   </AuthProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// i18n
import "./locales/i18n";

// highlight
import "./utils/highlight";

// scroll bar
import "simplebar-react/dist/simplebar.min.css";

// lightbox
// import "react-image-lightbox/style.css";

// map
// import "mapbox-gl/dist/mapbox-gl.css";

// editor
import "react-quill/dist/quill.snow.css";

// slick-carousel
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";

import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
// @mui
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// redux
import { store, persistor } from "./redux/store";
// contexts
import { SettingsProvider } from "./contexts/SettingsContext";

//
import React from "react";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/JWTContext";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
          {/* </LocalizationProvider> */}
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>
);

// Register Firebase Service Worker for Push Notifications
if ("serviceWorker" in navigator) {
  window.addEventListener("load", function () {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
