import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation, useParams } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// // guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// config
import {
  PATH_AFTER_LOGIN,
  ADMIN_ROLE,
  INSTALLATION_ROLE,
  INSTALLATION_SERVICE_ROLE,
  SERVICE_ROLE,
  CUSTOMER_SERVICE_ROLE,
} from "../config";
// components
import LoadingScreen from "../components/LoadingScreen";
import RoleBasedGuard from "src/guards/RoleBasedGuard";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralApp /> },
        {
          path: "vendor-management",
          element: (
            <RoleBasedGuard roles={[ADMIN_ROLE]}>
              <VendorManagement />
            </RoleBasedGuard>
          ),
        },
        {
          path: "policy",
          element: (
            <RoleBasedGuard roles={[ADMIN_ROLE]}>
              <PolicyManagement />
            </RoleBasedGuard>
          ),
        },

        {
          path: "beneficiery",
          children: [
            {
              element: (
                <Navigate to="/dashboard/beneficiery/beneficiery" replace />
              ),
              index: true,
            },
            {
              path: "beneficiery",
              element: (
                <RoleBasedGuard
                  roles={[
                    ADMIN_ROLE,
                    INSTALLATION_ROLE,
                    INSTALLATION_SERVICE_ROLE,
                  ]}
                >
                  <BeneficieryManagement />
                </RoleBasedGuard>
              ),
            },
            {
              path: "approval",
              element: (
                <RoleBasedGuard roles={[ADMIN_ROLE]}>
                  <Approvals />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: "service-management",
          // element: (
          //   <RoleBasedGuard
          //     roles={[
          //       ADMIN_ROLE,
          //       INSTALLATION_SERVICE_ROLE,
          //       SERVICE_ROLE,
          //       CUSTOMER_SERVICE_ROLE,
          //     ]}
          //   >
          //     <ServiceManagement />
          //   </RoleBasedGuard>
          // ),
          children: [
            {
              element: (
                <Navigate to="/dashboard/service-management/request" replace />
              ),
              index: true,
            },
            {
              path: "request",
              element: (
                <RoleBasedGuard
                  roles={[
                    ADMIN_ROLE,
                    INSTALLATION_SERVICE_ROLE,
                    SERVICE_ROLE,
                    CUSTOMER_SERVICE_ROLE,
                  ]}
                >
                  <ServiceManagement />
                </RoleBasedGuard>
              ),
            },
            {
              path: "Approval",
              element: (
                <RoleBasedGuard
                  roles={[
                    ADMIN_ROLE,
                    INSTALLATION_SERVICE_ROLE,
                    SERVICE_ROLE,
                    CUSTOMER_SERVICE_ROLE,
                  ]}
                >
                  <ServiceApprovals />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: "state",
          children: [
            {
              element: <Navigate to="/dashboard/state/state" replace />,
              index: true,
            },
            { path: "state", element: <ManageState /> },
            { path: "district", element: <ManageDistrict /> },
            { path: "sub-district", element: <ManageSubDistrict /> },
            { path: "village", element: <ManageVillage /> },
          ],
        },
        {
          path: "staff",
          children: [
            {
              element: <Navigate to="/dashboard/staff/admin" replace />,
              index: true,
            },
            {
              path: "field-engineer",
              element: (
                <RoleBasedGuard
                  roles={[
                    INSTALLATION_ROLE,
                    SERVICE_ROLE,
                    INSTALLATION_SERVICE_ROLE,
                  ]}
                >
                  <FieldEngineer />
                </RoleBasedGuard>
              ),
            },
            {
              path: "customer-service-representative",
              element: (
                <RoleBasedGuard roles={[ADMIN_ROLE, INSTALLATION_SERVICE_ROLE]}>
                  <CustomerService />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    { path: "/", element: <Navigate to="/dashboard/app" replace /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
// const Register = Loadable(lazy(() => import("../pages/auth/Register")));
// const ResetPassword = Loadable(
//   lazy(() => import("../pages/auth/ResetPassword"))
// );
// const NewPassword = Loadable(lazy(() => import("../pages/auth/NewPassword")));
// const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")));

const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const Approvals = Loadable(lazy(() => import("../pages/dashboard/Approvals")));

const FieldEngineer = Loadable(
  lazy(() => import("../pages/dashboard/FieldEngineer"))
);
const CustomerService = Loadable(
  lazy(() => import("../pages/dashboard/CustomerServiceCare"))
);
const ServiceManagement = Loadable(
  lazy(() => import("../pages/dashboard/ServiceManagement"))
);
const ServiceApprovals = Loadable(
  lazy(() => import("../pages/dashboard/ServiceApprovals"))
);
const BeneficieryManagement = Loadable(
  lazy(() => import("../pages/dashboard/BeneficieryManagement"))
);
const PolicyManagement = Loadable(
  lazy(() => import("../pages/dashboard/PolicyManagement"))
);
const VendorManagement = Loadable(
  lazy(() => import("../pages/dashboard/VendorManagement"))
);

const ManageState = Loadable(
  lazy(() => import("../pages/dashboard/state-management/ManageState"))
);
const ManageDistrict = Loadable(
  lazy(() => import("../pages/dashboard/state-management/ManageDistrict"))
);
const ManageSubDistrict = Loadable(
  lazy(() => import("../pages/dashboard/state-management/ManageSubDistrict"))
);
const ManageVillage = Loadable(
  lazy(() => import("../pages/dashboard/state-management/ManageVillage"))
);

// GENERAL
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Page403 = Loadable(lazy(() => import("../pages/Page403")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
