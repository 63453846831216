// hooks
import { useSelector } from "react-redux";
// utils
import createAvatar from "../utils/createAvatar";
//
import Avatar from "./Avatar";
import { IMG_URL } from "src/config";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  // get user
  const { user } = useSelector((state) => state?.user);
  console.log(user);

  return (
    <Avatar
      src={IMG_URL + user?.IMAGE_URL}
      alt={user?.FIRST_NAME}
      color={user?.avatar ? "default" : createAvatar(user?.FIRST_NAME).color}
      {...other}
    >
      {createAvatar(user?.FIRST_NAME).name}
    </Avatar>
  );
}
