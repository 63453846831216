// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDLU7FwhErSbRY01kmSO5dquFyg8QSbgPA",
  authDomain: "mycallbook-44536.firebaseapp.com",
  projectId: "mycallbook-44536",
  storageBucket: "mycallbook-44536.appspot.com",
  messagingSenderId: "569008406350",
  appId: "1:569008406350:web:00aaf4e4547e781e26e81f",
  measurementId: "G-DD6HC2F71S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Messaging and export it
const messaging = getMessaging(app);

export { messaging };
